.main {
  min-height: 100%;
  background-color: #f2f3f5;
  background-size: cover;
  background-repeat: no-repeat;
}

.page-title {
  text-align: center;
  font-size: 30px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.download-title {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  color: #1962f5;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 100px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141516dd;
  color: #7a7a7a;
  .copyright-box {
    margin-right: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .main {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .container {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  .download-title {
    font-size: 23px;
    margin: 0;
  }
  .footer {
    position: relative;
    font-size: 12px;
    flex-direction: column;
    .copyright-box {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
