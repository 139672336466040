.download-box {
  width: 100%;
  margin: 30px 0;
}
.icon-img {
  width: 24px;
  height: 24px;
}
.dropdown-item {
  padding: 5px 10px;
  border-radius: 3px;
  display: block;
  &:hover {
    background-color: #4982f5;
    color: #fff !important;
  }
}
.download-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  width: 15%;
  min-width: 150px;
  height: 40px !important;
  button {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    div {
      width: 100%;
      font-size: 14px;
      text-align: center;
    }
  }
  .arrow-icon {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
  }

  .icon-img {
    width: 24px;
    height: 24px;
    display: inline;
  }

  &:hover,
  &:active,
  &:focus {
    color: #4982f5;

    .arrow-icon {
      transform: rotate(0deg);
    }
  }
  img {
    margin-right: 10px;
  }
}
.download-list-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  .item-box {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 20px;
    gap: 10px;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid #e5e6eb;
    border-radius: 4px;
    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
    }
    h3 {
      margin: 0;
      white-space: nowrap;
    }
    .icon-box {
      img {
        width: 40px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .download-box {
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .download-list-box {
    flex-direction: column;
    justify-content: space-around;
    gap: 0;
    max-height: 350px;
    height: 100%;
  }
}
