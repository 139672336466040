@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

.word-break-box {
  word-break: break-all;
  min-width: 200px;
}

/* 自定义滚动条样式 */

/* 针对 WebKit 浏览器 (Chrome、Safari) */
::-webkit-scrollbar {
  width: 10px; /* 滚动条宽度 */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道颜色 */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
  border-radius: 6px; /* 滑块圆角 */
  border: 3px solid #f1f1f1; /* 滑块边框，创建间隙效果 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 滑块悬停时颜色 */
}
