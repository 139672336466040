.header {
  padding: 0 20px;
  height: 60px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.nav-box {
  width: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
